import { Component } from 'vue-property-decorator';
import { SwiperOptions } from '@/utils/swiperUtils';

import { CarouselSplitBlockViewModel } from './CarouselSplitModule.types';
import { VideoJsPlayerOptions } from 'video.js';

import {
  getComponentProps,
  getDefaultSwiperOptions,
  openModal,
  closeModal,
} from '@/utils/vueUtils';

import ResponsiveImageRatio from '@/components/ResponsiveImageRatio/ResponsiveImageRatio.vue';
import PlayPlaceholder from '@/components/PlayPlaceholder/PlayPlaceholder.vue';
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue';
import LexModal from '@/components/MicroModalComponent/MicroModalComponent.vue';
import Cutout from '@/components/CutoutComponent/CutoutComponent.vue';
import ArrowLong from '@/assets/icons/arrow-long.svg?inline';
import Cross from '@/assets/icons/cross.svg?inline';

import { getResizeImage } from '@/utils/commonUtils';

import { ImageWidths, ImageSizes, ImageSize } from '@/common/interfaces/SizeBreakpoints';

import CarouselPagination from '@/components/CarouselPagination/CarouselPagination.vue';
import CarouselArrow from '@/components/CarouselArrow/CarouselArrow.vue';

import FocusTrapDirective from '@/directives/FocusTrapDirective';
import { mixins } from 'vue-class-component';
import SwiperMixin from '@/mixins/SwiperMixin';
import { SegmentElement } from '@/plugins/utm/SegmentElement';

@Component({
  components: {
    VideoPlayer,
    LexModal,
    Cutout,
    ResponsiveImageRatio,
    ArrowLong,
    Cross,
    PlayPlaceholder,
    CarouselPagination,
    CarouselArrow,
  },
  directives: {
    'trap-focus': FocusTrapDirective,
  },
  props: getComponentProps<CarouselSplitBlockViewModel>({
    Title: 'Adventure with seasoned expedition teams',
    Text:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. aenean egestas rhoncus diam vitae vestibulum.',
    Slides: [
      {
        Title: 'Adam Maire',
        Text:
          'Naturalist, underwater videographer, captain, and historian, Adam Maire is dedicated to exploring around the globe with a goal of researching, documenting, and teaching others about the beauty, the power and the importance of the earth’s wild places.',
        Image: {
          Url: getResizeImage('adam-maire.jpg'),
          Alt: 'Adam Maire',
        },
        Cta: { Title: 'Read more', Link: '#' },
        Video: {
          Url:
            'https://player.vimeo.com/external/357523484.hd.mp4?s=420eaa08d3c07614792bd42e9933cbd568dbe11c&profile_id=174&autoplay=1&playsinline=1',
          Type: 'video/mp4',
          Poster: getResizeImage('adam-maire.jpg'),
        },
      },
    ],
  }),
})
export default class CarouselSplitModule extends mixins(SwiperMixin) {
  readonly p!: CarouselSplitBlockViewModel;
  wasInteracted = false;

  modalVideoOptions: VideoJsPlayerOptions = {
    autoplay: false,
    loop: false,
    muted: false,
    controls: true,
  };

  imageWidths: ImageWidths = {
    def: 463,
    sizes: {
      sm: 636,
      md: 992,
      lg: 793,
      xl: 993,
    },
  };

  get swiperOption(): SwiperOptions {
    return {
      ...getDefaultSwiperOptions(this.uuid),
      slidesPerView: 1.5,
      speed: 600,
      breakpoints: {
        320: {
          slidesPerView: 1.5,
        },
        560: {
          slidesPerView: 1.3,
        },
        1500: {
          slidesPerView: 1.2,
        },
      },
      on: {
        slideChange: () => this.onModuleInteraction('change'),
        touchStart: () => this.onModuleInteraction(),
      }
    };
  }

  cutoutSizes: Array<ImageSizes> = [
    {
      def: new ImageSize([122, 122]),
      sizes: {
        md: new ImageSize([138, 138]),
        xl: new ImageSize([164, 164]),
      },
    },
    {
      def: new ImageSize([68, 68]),
      sizes: {
        xl: new ImageSize([97, 97]),
      },
    },
  ];

  updated() {
    if (this.currentSlide && this.currentSlide.Video && this.currentSlide.Video.Type.includes('youtube')) {
      this.modalVideoOptions.autoplay = true;
    }
  }

  activeIndex: number = 0;
  get currentSlide() {
    return this.p.Slides.length ? this.p.Slides[this.activeIndex] : {} as any;
  }

  onModuleInteraction(type?: string) {
    if (!this.wasInteracted) {
      SegmentElement.sendEvent('moduleInteraction', {
        module_type_id: this.$attrs['data-segmentid'],
        module_type_name: this.$attrs.type,
        interaction_type: 'carousel',
        position: type === 'change' ? this.swiper.activeIndex : this.swiper.activeIndex + 1,
      })
      this.wasInteracted = true;
    }
  }

  openModal() {
    openModal(this.$modal, `video-player-${this.uuid}`);
    const module = document.querySelector('.carousel-split').getBoundingClientRect();
    let ModulePosition = module.top + window.pageYOffset;
    window.scrollTo({
      top: ModulePosition,
      behavior: 'smooth',
    });
  }

  closeModal() {
    closeModal(this.$modal, `video-player-${this.uuid}`);
  }

  mounted() {
    this.swiper.on('transitionStart', () => {
      this.activeIndex = this.swiper.activeIndex;
    });
  }
}
