import { Component, Vue, Watch } from 'vue-property-decorator';
import MicroModal from 'micromodal';
import { __values } from 'tslib';

const MicroModalProps = Vue.extend({
  props: {
    classWrapper: { type: String, default: 'modal' },
    closeTrigger: { type: String, default: '<button>close</button>' },
    id: { type: Number, default: 0 },
    trigger: { type: Boolean, default: false },
  },
});

@Component({
  components: {
    MicroModal,
  },
})
export default class MicroModalComponent extends MicroModalProps {
  // lets start come computed data here

  get classes() {
    return this.$props.classWrapper;
  }

  get modalId() {
    return this.$props.id;
  }

  get closeTriggerItem() {
    return this.$props.closeTrigger;
  }

  get triggerData() {
    return this.$props.trigger;
  }

  get closeData() {
    return this.$props.close;
  }

  // methods

  closeModal(id) {
    MicroModal.close(id);
  }

  // Watchers
  @Watch('triggerData')
  triggerOpen() {
    MicroModal.show(this.$props.id, {
      disableScroll: true, // [5]
      debugMode: true, // [9]
    });
  }
}
